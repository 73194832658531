import React from 'react';
import Layout from 'components/layout';
import { REVALIDATE_TIME } from 'lib/constants';
import { getWPPageStaticPaths, getWPPageBySlug } from 'lib/api';
import RenderContent from 'components/render-content';
import Link from 'next/link';
import addBlurImage from 'lib/add-blur-image';
import { isTimelyError } from 'lib/headless-types/TimelyError';

export default function Page({
    post,
    meta,
    title,
    jsonld,
    nav,
    footer,
    preview,
}) {
    // Todo create an awesome loading view
    if (!post) {
        return <div></div>;
    }

    const {
        structured_data,
        structured_data: { components },
    } = post;

    if (!structured_data.structure) {
        return (
            <Layout
                preview={preview}
                jsonld={jsonld}
                meta={meta}
                title={title}
                home={true}
                nav={nav}
                footer={footer}>
                <div className="has-dusk-4-background-color bb u-textCenter u-vSpacingMD">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center pb3 pxsb1 pxst1 pt1 plgt3 psmt3 mlgt2">
                                <h1 className="jumbo mt0 mb0 pbp5">
                                    Oops, we can’t find what you’re looking for.
                                </h1>
                                <p className="ptp5 mb0">
                                    We’re sorry about this, and we’ll take a
                                    look to see what’s happened.
                                </p>
                                <p>
                                    You can{' '}
                                    <Link href="/support/">
                                        <a>Chat</a>
                                    </Link>{' '}
                                    to send us a message, try Searching the
                                    website , or get in touch with our friendly{' '}
                                    <Link href="/support/">
                                        <a>Support</a>
                                    </Link>{' '}
                                    team.{' '}
                                </p>
                                <span id="search-anchor"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt2 u-vSpacingMD-top">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 pb4">
                            <div className="col-md-10 col-md-offset-1 text-center custom-search in-page-search mt2 mb2">
                                <form
                                    role="search"
                                    method="get"
                                    id="searchform"
                                    className="searchform"
                                    action="/">
                                    <div>
                                        <label
                                            className="screen-reader-text"
                                            htmlFor="s">
                                            Search for:
                                        </label>
                                        <div className="SearchOverlay-container">
                                            <input
                                                maxLength={25}
                                                placeholder="Start Typing"
                                                type="text"
                                                defaultValue=""
                                                name="s"
                                                id="s"
                                            />
                                            <input
                                                type="submit"
                                                className="Button Button--charcoal"
                                                value="Search"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout
            jsonld={jsonld}
            meta={meta}
            title={title}
            post={post}
            preview={preview}
            home={true}
            nav={nav}
            footer={footer}>
            <RenderContent
                structure={structured_data.structure}
                components={components}
            />
        </Layout>
    );
}

export async function getStaticPaths() {
    return await getWPPageStaticPaths();
}

export async function getStaticProps({ params: { page }, preview }) {
    let post = await getWPPageBySlug(page[page.length - 1]);
    if (isTimelyError(post)) {
        if (post.error === 404) {
            return {
                notFound: true,
            };
        }
    }
    
    const meta = post.yoast_meta || [];
    const title = post.yoast_title || 'Timely Salon Software';
    const jsonld = post.yoast_json_ld || false;
    const nav = post.nav;
    const footer = post.footer;

    if (!post.structured_data.structure) {
        return {
            notFound: true,
        };
    }

    post = await addBlurImage(post);

    return {
        props: {
            preview: preview || false,
            meta,
            title,
            post,
            jsonld,
            nav,
            footer,
        },
        revalidate: REVALIDATE_TIME,
    };
}
